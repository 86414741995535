<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)">
  <form class="auth-form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" name="form" novalidate>
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Reset Password</div>
    <p>Longer passwords such as phrases or sentences are more secure than short ones with numbers and symbols.</p>
    <div class="form-group password-group">
      <label>Password</label>
      <input type="password" class="form-control" [(ngModel)]="password" #pass="ngModel" name="pass">
      <div class="label" [ngClass]="getPassClass(scorePassword(password))">{{checkPassStrength(scorePassword(password))}}</div>
    </div>
    <div class="form-group password-group">
      <label>Confirm Password</label>
      <input type="password" class="form-control" [(ngModel)]="passwordRepeat" #passRep="ngModel" name="passRep" pattern="{{password}}">
      <div class="label label-empty" *ngIf="(passwordRepeat !== password) || !passwordRepeat">&nbsp;</div>
      <div class="label label-success" *ngIf="(passwordRepeat === password) && passwordRepeat">Same</div>
    </div>
    <div class="form-group text-right">
      <button class="btn btn-primary" type="submit">Next</button>
    </div>
  </form>
</div>