<div class="auth-wrap" style="background-image: url(assets/images/signup-bg.jpg)">
  <form class="auth-form" name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Sign Up</div>
    <p>Sign up using your work email to connect CloseQuickly to your team.</p>
    <div class="form-group" [ngClass]="{'has-error': f.submitted && !emailModel.valid}">
      <label for="email">Email Address</label>
      <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email" #emailModel="ngModel" required>
      <p class="help-block" *ngIf="f.submitted && !emailModel.valid">Must be a valid email</p>
    </div>
    <p class="error-mess">{{error}}</p>
    <div class="form-group text-right">
      <button class="btn btn-primary" [disabled]="loading">Get Started</button>
    </div>
  </form>
</div>