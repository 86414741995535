<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)" *ngIf="step == 0">
  <form class="auth-form auth-profile" [formGroup]="form" novalidate #f="ngForm" (submit)="next(f)">
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Profile</div>
    <p>Please complete and verify your profile <br> information below.</p>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="file file-img">
            <input type="file" accept="image/*" (change)="fileSelected($event)" #picture>
            <label for="picture" (click)="picture.click()" *ngIf="!previewAvatar"><span>+</span></label>
          </div>
          <div class="img-wrap" *ngIf="previewAvatar">
            <img [src]="previewAvatar" (click)="picture.click()" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <div class="form-group" [ngClass]="{'has-error': !form.controls.fname.valid && f.submitted}">
            <label for="name">First Name</label>
            <input type="text" class="form-control" formControlName="fname">
            <span class="help-block" *ngIf="form.controls.fname.errors?.required && f.submitted">This is required</span>
            <span class="help-block" *ngIf="form.controls.fname.errors?.maxlength && f.submitted">Maximum length is 35 characters</span>
          </div>
          <div class="form-group" [ngClass]="{'has-error': !form.controls.lname.valid && f.submitted}">
            <label for="name2">Last Name</label>
            <input type="text" class="form-control" formControlName="lname">
            <span class="help-block" *ngIf="form.controls.lname.errors?.required && f.submitted">This is required</span>
            <span class="help-block" *ngIf="form.controls.lname.errors?.maxlength && f.submitted">Maximum length is 35 characters</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group password-group" [ngClass]="{'has-error': !form.controls.company.valid && f.submitted}" *ngIf="!isInvite">
      <label>Company name</label>
      <input type="text" class="form-control" formControlName="company" (keyup)="companyChanged(form.controls.company.value)">
      <div class="label" [ngClass]="{'label-success': state == 0, 'label-danger': state == 1, 'label-warning': state == 2}" *ngIf="form.controls.company.value">
        {{stateMap[state]}}
      </div>
      <span class="help-block" *ngIf="form.controls.company.errors?.required && f.submitted">This is required</span>
      <span class="help-block" *ngIf="form.controls.company.errors?.maxlength && f.submitted">Maximum length is 35 characters</span>
    </div>
    <div class="form-group" *ngIf="!isInvite">
      <label>Your CloseQuickly URL</label>
      <input type="text" class="form-control" disabled value="{{previewURL}}">
    </div>
    <div class="form-group text-right">
      <button class="btn btn-primary" type="submit" [disabled]="loading">Next</button>
    </div>
  </form>
</div>

<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)" *ngIf="step == 1">
  <form class="auth-form" [formGroup]="form" novalidate #f="ngForm" (submit)="next(f)" novalidate>
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Almost Done...</div>
    <p>Secure your account by providing a password.Longer passwords such as phrases or sentences are more secure than short
      ones with numbers and symbols.</p>
    <div class="form-group password-group" [ngClass]="{'has-error': f.submitted && !form.controls.password.valid}">
      <label>Password</label>
      <input type="password" class="form-control" formControlName="password">
      <div class="label" [ngClass]="getPassClass(scorePassword(form.controls.password.value))" required>
        {{checkPassStrength(scorePassword(form.controls.password.value))}}
      </div>
      <span class="help-block" *ngIf="f.submitted && !form.controls.password.value">This is required</span>
      <span class="help-block" *ngIf="f.submitted && form.controls.errors?.minlength">Must be at least 6 characters long</span>
    </div>
    <div class="form-group password-group" [ngClass]="{'has-error': f.submitted && !passwordRepeat.valid}">
      <label>Confirm Password</label>
      <input type="password" class="form-control" [(ngModel)]="passRep" name="passwordRepeat" required pattern="{{form.controls.password.value}}"
        [ngModelOptions]="{standalone: true}" #passwordRepeat="ngModel">
      <p class="help-block" *ngIf="f.submitted && passwordRepeat.errors?.pattern">Passwords don't match</p>
      <p class="help-block" *ngIf="f.submitted && passwordRepeat.errors?.required">This is required</p>
    </div>
    <div class="form-group text-right">
      <button class="btn btn-primary" type="submit" [disabled]="loading">Finish</button>
    </div>
  </form>
</div>