<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)">
  <form class="auth-form"  name="form" (ngSubmit)="f.form.valid && sendEmail()" #f="ngForm" novalidate>
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Login Help</div>
    <p>Provide us with your email address and we’ll send you instructions on how to access your account.</p>
    <div class="form-group" [ngClass]="{'has-errors': emailEl.valid}">
      <label for="email">Email Address</label>
      <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email" #emailEl="ngModel">
      <p class="help-block" *ngIf="f.submitted && !emailEl.valid">Must be a valid email</p>
    </div>
    <div class="form-group flex-group">
      <button class="btn btn-default" onclick="location.href='/signin'">Cancel</button>
      <button class="btn btn-primary" type="submit">Send Instructions</button>
    </div>
  </form>
</div>