<div class="auth-wrap" style="background-image: url(assets/images/signup-bg.jpg)">
	<div class="auth-form" *ngIf="!loading">
		<div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
		<div class="title">Join CloseQuickly</div>
		<p>Gather your forces! Your organization team is the key to making conversions</p>
		<div class="org-list">
			<div class="org-item" *ngFor="let invite of invites">
				<input type="checkbox" id="{{invite._id}}" [(ngModel)]="invite.selected">
				<label for="{{invite._id}}">{{invite.company.name}}</label>
				<div class="join" (click)="join(invite._id)">Join</div>
			</div>
		</div>
	</div>
</div>