<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)">
  <form name="form" class="auth-form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Login</div>
    <div class="form-group">
      <a href="/api/auth/google?state=login" class="social google"><i class="fa fa-google-plus"></i>Google Log In</a>
      <a href="/api/auth/linkedin?state=login" class="social linked"><i class="fa fa-linkedin"></i>Linkedin Log In</a>
    </div>
    <div class="divider"><span>or</span></div>
    <div class="form-group" [ngClass]="{'has-error': f.submitted && !username.valid}" id="username">
      <label for="email">Email Address</label>
      <input type="email" class="form-control" [(ngModel)]="model.username" name="username" #username="ngModel" required>
      <p class="help-block" *ngIf="f.submitted && !username.valid">Must be a valid email</p>
    </div>
    <div class="form-group" [ngClass]="{'has-error': f.submitted && !password.valid}">
      <label for="password">Password</label>
      <input type="password" class="form-control" id="password"  [(ngModel)]="model.password" #password="ngModel" name="password" required>
      <p class="help-block" *ngIf="f.submitted && !password.valid">Password is required</p>
    </div>
    <p class="error-mess">{{error}}</p>
    <div class="form-group row">
      <div class="col-xs-6">
        <a href="/restore">Forgot your password?</a>
        <a href="/signup">Don't have an account?</a>
      </div>
      <div class="col-xs-6 text-right">
        <button class="btn btn-primary" [disabled]="loading">Log In</button>
      </div>
    </div>
  </form>
</div>