<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)" *ngIf="!expired">
  <form class="auth-form">
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Welcome</div>
    <p>Before we get started, there’s some housekeeping to do. Create a profile using an existing account</p>
    <div class="form-group">
      <a href="/api/auth/google?state=retrieve" class="social google"><i class="fa fa-google-plus"></i>Google Profile</a>
      <a href="/api/auth/linkedin?state=retrieve" class="social linked"><i class="fa fa-linkedin"></i>Linkedin Profile</a>
    </div>
    <div class="divider"><span>or</span></div>
    <div class="form-group flex-group">
      <button class="btn btn-primary" [routerLink]="['/complete/profile', {}]">Create manually</button>
    </div>
  </form>
</div>

<div class="auth-wrap" style="background-image: url(assets/images/login-bg.jpg)" *ngIf="expired">
  <form class="auth-form auth-center">
    <div class="logo-auth"><img src="assets/images/auth-logo.jpg" alt=""></div>
    <div class="title">Error !</div>
    <p>This link is either not valid or expired.</p>
  </form>
</div>